import React from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { NamedRedirect } from '../../components'

export default function SearchPage() {
   const location= useLocation()
   const queryParams = new URLSearchParams(location.search)
   const listingType = queryParams.get('pub_listingType');

   return <NamedRedirect name={listingType==='gym'?'SearchPageGym':'SearchPageProduct'} search={location?.search?location?.search:"pub_listingType=product-selling"}/>
  
}
